import React from "react";

export default class Contact extends React.Component {
  state = {
    content: this.props.title,
    email: "hello@martin.is",
    index: 1
  };

  handleClick = () => {
    window.location.href = `mailto:${this.state.email}`;
  };

  render() {
    return (
      <>
        <button className="contact ns" onClick={this.handleClick}>{this.state.content}<span className="mail-icon"></span></button>
      </>
    );
  }
}