import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
// import Image from "../components/image"
import SEO from "../components/seo"

import Cycle from "../components/cycle"
import Tag from "../components/tag"
import Tracky from "../components/tracky"
import Contact from "../components/contact"
import Socials from "../components/socials"

const IndexPage = () => (
  <Layout>
    <Tracky />
    <SEO />
    <Header />
    <div className="main-text">
      <p>
        Hi, I’m Martin—a designer and engineering student based in Copenhagen,{" "}
        <a
          href="https://www.iso.org/obp/ui/#iso:code:3166:DK"
          target="_blank"
          rel="noreferrer noopener"
        >
          DK
        </a>
        . I have several years of experience in branding, UX design and various
        types of product development.
      </p>
      <p>
        After a slight detour through the field of medicinal chemistry, I have
        returned to my creative roots and now study design engineering at{" "}
        <a
          href="https://www.dtu.dk/english"
          target="_blank"
          rel="noreferrer noopener"
        >
          DTU
        </a>
        .
      </p>
      <p>
        My main passions lie in challenging my creativity—often by{" "}
        <Cycle
          array={[
            "solving",
            "optimisation problems",
            "product issues",
            "wicked design problems",
            "industry & societal challenges",
            "bugs in my code",
          ]}
        />
        {", "}
        <Cycle
          array={[
            "building",
            "innovative products",
            "cardboard mock-ups",
            "functional prototypes",
            "web apps",
            "open-source 3D printers",
          ]}
        />
        {" or "}
        <Cycle
          array={[
            "designing",
            "for humans",
            "green & sustainable solutions",
            "concepts in my sketchbook",
            "digital interfaces",
            "memorable user experiences",
            "CAD models",
          ]}
        />
        .
      </p>
    </div>
    <div className="main-socials">
      <Contact title="Get in touch" />
      <div className="socials ns">
        Or find me on:
        <Socials
        twitter="https://twitter.com/martinsor"
        instagram="https://www.instagram.com/martineering/"
        github="https://github.com/martineering"
      />
      </div>
    </div>
    <div className="main-tags">
      <span className="title">Areas of Interest</span>
      <Tag title="CAD" />
      <Tag title="Circular Economy" />
      <Tag title="DDDM" />
      <Tag title="Design Thinking" />
      <Tag title="Digital Electronics" />
      <Tag title="Eco-design" />
      <Tag title="Human-Computer Interaction" />
      <Tag title="IoT" />
      <Tag title="Life-cycle Assessment" />
      <Tag title="Machine Learning" />
      <Tag title="Product Optimisation" />
      <Tag title="Rapid Prototyping" />
      <Tag title="Sustainable Development" />
    </div>
  </Layout>
)

export default IndexPage
