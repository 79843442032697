import React from "react";

export default class Cycle extends React.Component {
  state = {
    test: this.props.array,
    index: 1
  };

  handleClick = () => {
    let i = this.state.index < this.state.test.length - 1 ? this.state.index + 1 : 1;
    this.setState({ index: i });
  };

  render() {
    return (
      <>
        <span className="cycle-lead lead-color ns" onClick={this.handleClick}>{this.state.test[0]}</span>{" "}
        {this.state.test[this.state.index]}
      </>
    );
  }
}