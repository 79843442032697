import React from "react"

const order = [
  "twitter",
  "instagram",
  "github",
  "dribbble",
  "linkedin",
  "behance",
]

export default class Socials extends React.Component {
  state = {
    twitter: this.props.twitter,
    instagram: this.props.instagram,
    github: this.props.github,
    dribbble: this.props.dribbble,
    linkedin: this.props.linkedin,
    behance: this.props.behance,
    index: 0,
  }

  render() {
    return (
      <div>
        {typeof this.state[order[0]] !== "undefined" && <a className="icon twitter" href={this.state[order[0]]} target="_blank" rel="noreferrer noopener"></a>}
        {typeof this.state.instagram !== "undefined" && <a className="icon instagram" href={this.state.instagram} target="_blank" rel="noreferrer noopener"></a>}
        {typeof this.state.dribbble !== "undefined" && <a className="icon dribbble" href={this.state.dribbble} target="_blank" rel="noreferrer noopener"></a>}
        {typeof this.state.github !== "undefined" && <a className="icon github" href={this.state.github} target="_blank" rel="noreferrer noopener"></a>}
      </div>
    )
  }
}
