import React from "react";

export default class Tag extends React.Component {
  state = {
    string: this.props.title.replace(" ", "+"),
    category: this.props.category
  };

  handleClick = () => {
    window.open(`https://www.mendeley.com/search/?page=1&query=${this.state.string}&sortBy=citationCount`, "_blank") || window.location.replace(`https://www.mendeley.com/search/?page=1&query=${this.state.string}&sortBy=citationCount`);
  };

  render() {
    // console.log(this.state.string);
    return (
      <>
        <button className="ns" onClick={this.handleClick}>{this.props.title}</button>
      </>
    );
  }
}